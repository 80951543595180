<template>
  <div class="history-orders h-100 w-100 p-3 mt-5">
    <!--h2 class="text-dark-darken pt-4 mb-4 font-weight-bold">
      {{ $t('pageTitles.historyOrders') }}
    </h2-->
    <ag-grid-vue
      class="ag-theme-material rounded"
      rowSelection="single"
      :rowDeselection="true"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :animatedRows="true"
      :gridOptions="gridOptions"
      :localeText="localeText"
      :modules="modules"
      :defaultColDef="defaultColDef"
      :frameworkComponents="frameworkComponents"
      :loadingOverlayComponent="loadingOverlayComponent"
      :loadingOverlayComponentParams="loadingOverlayComponentParams"
      :components="components"
      :style="{ width: '100%', height: `calc(100% - 150px)` }"
      @grid-ready="onGridReady"
      @grid-size-changed="onGridSizeChanged"
      @selection-changed="setRowSelection"
    ></ag-grid-vue>
    <lateral-slider
      title="Pedidos"
      v-if="sliderOpen && items.length"
      @close="closeSlider"
      :width="650"
    >
      <div class="d-flex px-2 justify-content-between align-items-center pb-2">
        <p class="text-dark font-weight-bold mb-0">
          {{ $t('orderEditModals.infoDetails.partner') }}
        </p>
        <p class="text-dark font-weight-bold mb-0">{{ $t('form.labels.nfeKey') }}</p>
        <p class="text-dark font-weight-bold mb-0">{{ $t('agGrid.columnDefs.status') }}</p>
      </div>
      <div
        class="d-flex px-2 justify-content-between align-items-center data-row py-2"
        v-for="item in items"
        :id="`item-line-${item.id}`"
        :key="item.id"
      >
        <p class="text-dark font-weight-bold mb-0">{{ operation }}</p>
        <p class="text-dark font-weight-bold mb-0">{{ item.invoice_key }}</p>
        <uello-tag
          v-if="item.status !== 'error'"
          class="px-2"
          :closeable="false"
          :color="getVariant(item.status)"
          :text="item.status"
        />
        <uello-tooltip
          :closeable="false"
          color="red"
          direction="left"
          :multilined="false"
          v-if="item.status === 'error'"
          :text="errorFilter(item.message)"
        >
          <div class="w-100">
            <p class="m-0">
              <uello-tag
                class="px-2"
                :closeable="false"
                :color="getVariant(item.status)"
                :text="item.status"
              />
            </p>
          </div>
        </uello-tooltip>
      </div>
    </lateral-slider>
    <movidesk-modal
      :hasButton="movideskHasButton"
      :is-open="movideskIsOpen"
      :prefill="{}"
      @close="closeMovidesk"
      @open="openMovidesk"
    />
  </div>
</template>

<script>
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { UelloTag, UelloTooltip } from '@uello/componentello';
import api from '@/services/api';
import { safeStorage } from '@/utils';
import { movidesk } from '@/mixins';

import FollowAgGridLoadingOverlay from './components/u-follow/FollowAgGridLoadingOverlay.vue';

export default {
  name: 'history-orders',
  mixins: [movidesk],
  /* eslint-disable vue/no-unused-components */
  components: {
    FollowAgGridLoadingOverlay,
    UelloTooltip,
    UelloTag,
    MovideskModal: () => import('@/components/MovideskTicketFormModal.vue'),
  },
  data() {
    return {
      movideskHasButton: true,
      tickets: [],
      sliderOpen: false,
      items: [],
      rowData: null,
      gridApi: null,
      columnApi: null,
      localeText: this.$t('agGrid.localeText'),
      components: null,
      frameworkComponents: null,
      loadingOverlayComponent: null,
      loadingOverlayComponentParams: null,
      gridHeight: 400,
      modules: AllCommunityModules,
      paginationSize: 5,
      columnDefs: null,
      defaultColDef: {
        resizable: true,
        filter: false,
      },
      gridOptions: {
        alwaysShowVerticalScroll: true,
        rowStyle: {
          borderColor: 'transparent',
        },
        getRowStyle(params) {
          if (params.node.rowIndex % 2 === 0) {
            return { background: '#fff' };
          }
          return null;
        },
      },
    };
  },
  async beforeMount() {
    this.frameworkComponents = {
      customLoadingOverlay: 'FollowAgGridLoadingOverlay',
    };
    this.loadingOverlayComponent = 'customLoadingOverlay';
    this.components = {
      FollowAgGridLoadingOverlay,
    };

    const { data: response } = await api.get('/orders/tickets');

    this.tickets = response.data.items;

    if (this.tickets.length) {
      const items = response.data.items.map(i => {
        const { ticket, created_at, count_pending, count_success, count_errors } = i;
        const status = this.getStatus({ count_pending, count_success, count_errors });

        return { ticket, status, created_at, count_pending, count_success, count_errors };
      });

      this.items = response.data.items;

      this.setColumnDef(items);
      this.setRowData(items);
    } else {
      this.gridApi.hideOverlay();
      this.setRowData([]);
    }
  },
  computed: {
    operation() {
      const { operations } = safeStorage.getItem('config');

      return operations[0].partner;
    },
  },
  methods: {
    errorFilter(error) {
      error =
        error === 'Pedido fora da área de abrangência'
          ? 'CEP Não Encontrado na Tabela de Frete'
          : error;
      return error.includes('[FILE]') ? this.$t('messages.genericFileError') : error;
    },
    onGridReady({ api: gridapi, columnApi }) {
      this.gridApi = gridapi;
      this.columnApi = columnApi;
      this.gridApi.showLoadingOverlay();
      gridapi.sizeColumnsToFit();
    },
    onGridSizeChanged(params) {
      params.api.sizeColumnsToFit();
    },
    setColumnDef(data) {
      this.columnDefs = Object.keys(data[0]).map(field => ({
        headerName: this.$t(`agGrid.columnDefs.${field}`),
        field,
        sortable: false,
        filter: false,
        width: this.getWidth(field),
        cellStyle: params => this.getStyle(field, data[0][field], params),
      }));
    },
    getStyle(field, data, params) {
      const defaultStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };

      const colors = {
        sucesso: '#1cca42',
        pendente: '#e6b93b',
        'erro no envio': '#e63b4f',
      };

      let style = null;

      switch (field) {
        case 'ticket':
          style = null;
          break;
        case 'status':
          style = {
            ...defaultStyle,
            padding: '3px',
            background: colors[params.value] ? colors[params.value] : colors.sucesso,
            color: '#fff',
            height: '36px',
            marginTop: '6px',
            marginLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0.25rem',
            width: '190px',
          };
          break;
        default:
          style = defaultStyle;
      }

      return style;
    },
    setRowData(data) {
      const filteredData = data.map(item => {
        item.status = item.status.text;
        return item;
      });
      this.rowData = filteredData;
    },
    getVariant(status) {
      switch (status) {
        case 'error':
          return 'red';
        case 'pending':
          return 'ocher';
        default:
          return 'green';
      }
    },
    setRowSelection() {
      const [row] = this.gridApi.getSelectedRows();

      if (row) {
        this.setItems(row.ticket);
        this.sliderOpen = true;
      }
    },
    setItems(ticket) {
      this.items = this.tickets.find(t => t.ticket === ticket).orders;
    },
    closeSlider() {
      this.gridApi.deselectAll();
      this.sliderOpen = false;
    },
    getStatus({ count_pending, count_success, count_errors }) {
      if (count_pending === 0 && count_errors === 0 && count_success > 0) {
        return {
          type: 'success',
          text: 'sucesso',
        };
      }
      if (count_pending === 0 && count_errors > 0) {
        return {
          type: 'error',
          text: 'erro no envio',
        };
      }
      if (count_pending > 0) {
        return {
          type: 'pending',
          text: `pendente`,
        };
      }

      return {
        type: 'success',
        text: `${count_success} enviado(s) com sucesso`,
      };
    },
    getWidth(column) {
      switch (column) {
        case 'count_pending':
        case 'count_success':
        case 'count_errors':
          return 120;
        case 'ticket':
          return 500;
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
.history-orders {
  .tooltip .tooltip-inner {
    max-width: 96% !important;
    width: 96% !important;
    margin: 0 auto !important;
  }

  .data-row {
    &:nth-child(odd) {
      background-color: #fff;
    }

    &:nth-child(even) {
      background-color: #f2f2f2;
    }
  }

  .ag-theme-material .ag-input-wrapper input:not([type]):focus,
  .ag-theme-material .ag-input-wrapper input[type='text']:focus,
  .ag-theme-material .ag-input-wrapper input[type='number']:focus,
  .ag-theme-material .ag-input-wrapper input[type='tel']:focus,
  .ag-theme-material .ag-input-wrapper input[type='date']:focus,
  .ag-theme-material .ag-input-wrapper input[type='datetime-local']:focus {
    border-color: var(--primary);
  }

  .ag-theme-material .ag-row {
    cursor: pointer;
  }

  .ag-theme-material .ag-row:not(.ag-row-first .ag-row-selected) {
    border: 0;

    &:nth-child(even):not(.ag-row-selected) {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  .ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
  .ag-theme-material .ag-ltr .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
  .ag-theme-material .ag-rtl .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
    border: 0;
  }

  .ag-theme-material .ag-filter .ag-filter-apply-panel {
    display: flex;
    justify-content: space-between;
    button {
      color: #fff;
      font-weight: bold;
      border: 1px solid var(--primary);
      padding: 2px 6px;
      border-radius: 0.25rem;
      transition: all 200ms ease;
      margin: 0;
      background-color: var(--primary);

      &:nth-child(3n) {
        margin-left: 1rem;
      }

      &:hover {
        background-color: #fff;
        color: var(--primary);
      }
    }
  }

  .ag-theme-material .ag-row-selected {
    background-color: rgba(0, 0, 0, 0.15) !important;
    /* background-color: var(--primary);
    color: #fff; */
  }

  .ag-theme-material .ag-icon {
    color: #fff;
    background-color: var(--primary);
    border-radius: 0.25rem;
    padding: 2px;
  }

  .ag-header-cell-label .ag-header-cell-text {
    font-weight: bold;
    text-transform: uppercase;
  }

  .ag-root.ag-layout-normal {
    border-radius: 0.25rem;
  }
  .ag-root-wrapper-body {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .data-grid {
    width: 100%;
    height: 500px;
  }
}
</style>
